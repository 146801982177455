







import { Component } from "vue-property-decorator";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { BaseForm } from "./BaseForm";
import "progress-tracker/src/styles/progress-tracker.scss";


@Component
export default class GetQuoteForm extends BaseForm {
}
