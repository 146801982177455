









































































































































































































































import { Component } from "vue-property-decorator";
import axios from "axios";
import { BaseForm } from "./BaseForm";
import "progress-tracker/src/styles/progress-tracker.scss";

@Component
export default class TrackShippingForm extends BaseForm {
  public endpoint = process.env.VUE_APP_TRACKING_ENDPOINT;
  public labels: Record<string, string> = {
    trackingNo: "Tracking Number",
  };

  public state: "busy" | "tracked" | "none" = "none";
  public trackingStatus: number | null = null;
  public data!: any;

  public model: Record<string, any> = {
    trackingNo: null,
  };

  get ok() {
    if (this.trackingStatus !== 200) {
      return false;
    }
    return this.data && this.data.data && Array.isArray(this.data.data);
  }

  get stages() {
    return this.data.data;
  }

  get currentStage() {
    return Array.from(this.stages)
      .filter((stage: any) => {
        return stage.status;
      })
      .pop();
  }

  public async send() {
    this.state = "busy";
    this.$alert.clear();
    try {
      const response = await axios.post(
        this.endpoint,
        {
          tracking_number: this.model.trackingNo,
        },
        {
          validateStatus: (status) => {
            return [200, 404].includes(status);
          },
        }
      );
      this.trackingStatus = response.status;
      this.data = response.data;
      if (this.trackingStatus === 404) {
        this.$alert.addAlert({
          type: "danger",
          message: `No tracking information found for #${this.model.trackingNo}`,
        });
      }
    } catch (error) {
      console.error(error);
      this.$alert.addAlert({
        type: "danger",
        message: "An error occured. Try again later",
      });
    } finally {
      this.state = "tracked";
    }

    this.$emit("done", {});
  }
}
