










import { Component, Vue, Prop } from "vue-property-decorator";
import ShippingForm from "./../components/forms/ShippingForm.vue";

@Component({
  components: {
    ShippingForm,
  },
  metaInfo: {
    title: "Shipping",
  },
})
export default class GetQuote extends Vue {
  @Prop({ default: () => ({}) })
  public query!: Record<string, any>;

  onTrackingDone() {
    return;
  }
}
