










import { Component, Vue, Prop } from "vue-property-decorator";
import TrackShippingForm from "./../components/forms/TrackShippingForm.vue";

@Component({
  components: {
    TrackShippingForm,
  },
  metaInfo: {
    title: "Track Shipping",
  },
})
export default class Login extends Vue {
  @Prop({ default: () => ({}) })
  public query!: Record<string, any>;

  onTrackingDone() {
    return;
  }
}
